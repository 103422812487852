import axios from 'axios';


// export default axios.create({
//     baseURL: `http://localhost:8000/`,
//     withCredentials: true,
//     headers:{
//       'X-Requested-With':'XMLHttpRequest',
//       'Accept':'Application/json',
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Credentials": true,
//     },
  
//   });
  export default axios.create({
    baseURL: `https://backendnew.vishwamotorsstock.com/`,
    headers:{
      'X-Requested-With':'XMLHttpRequest',
      'Accept':'Applicarion/json'
    },
    withCredentials: true,
  });