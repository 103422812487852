import styles from './AdminPortal.module.css';
import {Outlet,useLocation, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState,} from 'react';
import { logOut } from './requests';
import { UserContext } from './ProtectedRoutes';
import { useRef } from 'react';
function AdminPortal() {
  let location=useLocation();  
  const nav = useNavigate();
  const [screen,setScreen]=useState(window.innerWidth);
  window.onresize = function() {
   setScreen(window.innerWidth)
};
const popupToggle=useRef();

  const logoutUser =() =>{
   sessionStorage.setItem('loginPage','true');
   logOut(); 
   nav('/login'); 
  }
const datas=useContext(UserContext);

const showPopup=()=>{
   popupToggle.current.style.display="block";
   setTimeout(()=>popupToggle.current.style.display="none", 1200);
   }

const checkAndNav=(route)=>{
   if(datas[0].role==='admin')
   {
    nav(route);
   }
   else if(datas[0].abilitiy?.find(obj => obj.table === 'user' && obj.ability === 'create'))
   {
     nav(route);
   
   }
   else{
    showPopup();
   }
}

  const [mobileNavShow,setMobileNavShow]=useState(false);
  return (
    <div className={styles.Page}>
     {screen >=768 && (
        
      <div className={styles.SideNav}>
         <div className={styles.ProfilePicture}>

         </div>
         <p className={styles.UserName}>User name</p>
         <hr className={styles.HR}></hr>
        <div className={styles.NavItemGroup}>
           <div onClick={()=>nav('/')} className={location.pathname==='/'? styles.SelectedNavItem : styles.NavItem}>
              Dashboard 
           </div> 
           <div onClick={()=>checkAndNav('/projectManager')}  className={location.pathname==='/projectManager'? styles.SelectedNavItem : styles.NavItem}>
              Project Manager
           </div> 
           <div onClick={()=>nav('/productManager')}  className={location.pathname==='/productManager'? styles.SelectedNavItem : styles.NavItem}>
              Product Manager
            </div> 
            <div onClick={()=>nav('/adminPeopleManager')}  className={location.pathname==='/adminPeopleManager'? styles.SelectedNavItem : styles.NavItem}>
              People Manager
            </div>
        </div>
      </div>)
      }
      <div className={styles.NavBar}>
         {
            screen <768 &&
            (
            <FontAwesomeIcon onClick={()=>setMobileNavShow(!mobileNavShow)} className={styles.MenuIcon} icon={faBars}></FontAwesomeIcon>
            )  
         }
      <div className={styles.Logout} onClick={logoutUser}>Logout</div>
         </div>
      <div className={styles.RestPage}>
      
         {
          screen < 768 && mobileNavShow &&  
          (
            <div className={styles.MobileNav}>
               <div className={location.pathname==='/dashboard'? styles.SelectedNavItem : styles.NavItem}>
                  Dashboard 
               </div> 
               <div className={location.pathname==='/projectManager'? styles.SelectedNavItem : styles.NavItem}>
                  Project Manager
               </div> 
               <div className={location.pathname==='/productManager'? styles.SelectedNavItem : styles.NavItem}>
                  Product Manager
               </div> 
               <div className={location.pathname==='/employeeManager'? styles.SelectedNavItem : styles.NavItem}>
                  People Manager
               </div>
            </div>
          ) 
         }   

        <div ref={popupToggle} className={styles.Popup}>
           You are not Authorized!
        </div>
         <Outlet/>
      </div>
    </div>

  );
}

export default AdminPortal;
