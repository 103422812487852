import Api from './Api';


const datas=JSON.parse(sessionStorage.getItem('ability'));


export const goodsCount =async ()=>{
    let response;
     await  Api.get('api/goodsCount').then((res)=>{

       response={success:res.data};
     }).catch((error)=> {
      response={error:error};
      sessionStorage.removeItem('auth');
     })
     return response;
}


export const logOut =async ()=>{
  let response;
   await  Api.post('api/logout').then((res)=>{
     response={success:res.data};
   }).catch((error)=> {
    response={error:error};
   })
   console.log(response);
   sessionStorage.removeItem('auth');
}

export const singleItem =async (params)=>{
  let response;
   await  Api.get('api/singleItem/'+params.params.obj+'/'+params.params.id).then((res)=>{
      params.params.obj==='stock'? response=res.data:response=res.data.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return {'type':params.params.obj,'response': response};
}

export const productTransactions=async()=>{
  let response;
   await  Api.get('api/productTransactionCount').then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
 
}

export const allGoods=async()=>{
  let response;
   await  Api.get('api/good').then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}
export const testRequest =async()=>{
  let response;
  sessionStorage.setItem('loginPage',false); 
  await  Api.get('api/test').then((res)=>{
    response=res.data;
  }).catch((error)=> {
   sessionStorage.removeItem('auth');
   response={error:error};
  })
  if(response===1){
    sessionStorage.setItem('auth',true);
  }
 return response;
}

export const allGoodsPaginate=async(link)=>{
  let response;
   await  Api.get('api/good',{params:{page:link}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}


export const allTimeGrns=async()=>{
  let response;
   await  Api.get('api/allTimeGrns').then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allTimeGrnsPaginate=async(link)=>{
  let response;
   await  Api.get('api/allTimeGrns/',{params:{page:link}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}
export const allTimeSales=async()=>{
  let response;
   await  Api.get('api/allTimeSales').then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allTimeSalesPaginate=async(link)=>{
  let response;
   await  Api.get('api/allTimeSales/',{params:{page:link}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const peopleData=async()=>{
  let response;
   await  Api.get('api/peopleData').then((res)=>{
     response=res.data.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const getProfessions=async()=>{
  let response;
   await  Api.get('api/getProfessions').then((res)=>{
     response=res.data.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}




export const allGoodsWithTime=async(from,to)=>{
  let response;
   await  Api.get('api/allGoodsWithinPeriod',{params:{from:from,to:to}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allGoodsWithTimePaginate=async(page,from,to)=>{
  let response;
   await  Api.get('api/allGoodsWithinPeriod', {
    params: {
      page: page,
      from:from,
      to:to
    }}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allSales=async(from,to)=>{
  let response;
   await  Api.get('api/sales',{params:{from:from,to:to}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allSalesPaginate=async(page,from,to)=>{
  let response;
   await  Api.get('api/sales',{params:{page:page,from:from,to:to}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const allGrns = async (from, to) => {
  try {
    const response = await Api.get(`api/grns`,{params:{from:from,to:to}});
    return response.data;
  } catch (error) {
    // Handle errors
    sessionStorage.removeItem('auth');
    return { error: error.message }; // You can adjust error handling as needed
  }
};
export const allGrnsPaginate=async(page,from,to)=>{
  let response;
   await  Api.get('api/grns',{params:{page:page,from:from,to:to}}).then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const newGrn=async(data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'good' && obj.ability === 'create'))
  {
   await  Api.post('api/newGrn',data).then((res)=>{
     response=true;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  }
  return response;
}

export const newSale=async(data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'good' && obj.ability === 'create'))
  {
   await  Api.post('api/newSale',data).then((res)=>{
     response=true;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  }
  return response;
}

export const newProfession=async(data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'profession' && obj.ability === 'create'))
  {
   await  Api.post('api/profession',data).then((res)=>{
    response=true;
  }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  }
  return response;
}
export const newPerson=async(data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'user' && obj.ability === 'create'))
  {
   await  Api.post('api/admin',data).then((res)=>{
    response=true;
  }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  }  
  return response;
}
export const getProjects=async()=>{
  let response;
   await  Api.get('api/getProjects').then((res)=>{
     response=res.data;
   }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  return response;
}

export const newProject=async(data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'project' && obj.ability === 'create'))
  {
   await  Api.post('api/newProject',data).then((res)=>{
    response=true;
  }).catch((error)=> {
    sessionStorage.removeItem('auth');
    response={error:error};
   })
  }
  return response;
}


export const editGood=async(id,data)=>{
  let response;
  if(sessionStorage.getItem('role')==='admin'|| datas[0].abilitiy?.find(obj => obj.table === 'good' && obj.ability === 'create'))
  {
    await  Api.put('api/good/'+id,{params:{data:data}}).then((res)=>{
      response=true;
    }).catch((error)=> {
      sessionStorage.removeItem('auth');
      response={error:error};
     })
  }

  return response;
}

export const deleteGood=async(id)=>{
  let response;
 
    await  Api.delete('api/good',{params:{id:id}}).then((res)=>{
      response=true;
    }).catch((error)=> {
      sessionStorage.removeItem('auth');
      response={error:error};
     })

  return response;
}
