import React from "react";
import { createContext,useState } from "react";

import { Navigate, Outlet } from "react-router-dom";
export const UserContext = createContext();

const ProtectedRoutes = () => {
	// TODO: Use authentication token
	const sessionStorageToken = sessionStorage.getItem("auth");
	const ability=useState({role:sessionStorage.getItem('role'),ability:sessionStorage.getItem('ability')?JSON.parse(sessionStorage.getItem('ability')):null});

	return sessionStorageToken ?   <UserContext.Provider value={ability}>
	<Outlet />    </UserContext.Provider>
 : <Navigate to="/login"  replace />;
};

export default ProtectedRoutes;